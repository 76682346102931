<template>
	<div class="toggleOption">
		<TnParagraph>Betal alt nå</TnParagraph>
		<TnToggle
			v-model="modelValue"
			class="toggle"
		/>
		<TnParagraph>Splitt</TnParagraph>
	</div>
</template>

<script setup lang="ts">
const modelValue = defineModel<boolean>();
</script>

<style lang="scss" scoped>
.toggleOption {
	display: flex;
	gap: $spacing-m;
	align-items: center;
	justify-content: center;
}

:deep(.tn-toggle) {
	.track {
		background-color: $color-cta-default !important;

		&:hover {
			background-color: $color-cta-default !important;
		}
	}
}
</style>
